angular.module("aerosApp").controller("ProjectContactsCtrl", [ "$scope", "aerosApi", "Notification", "contactTypes",
    "orgContactModal", "uploadLogoModal", "tinyImg",
  function($scope, aerosApi, Notification, contactTypes, orgContactModal, uploadLogoModal, tinyImg) {
	$scope.$watch("projectReady", function (newVal) {
		if (!newVal) return;
		if (!$scope.allow['viewContacts']) return $scope.reject($scope.projectId);
		$scope.tinyImg = tinyImg;

		$scope.reportContacts = {};
		function loadContacts () {
			return aerosApi.getAllContacts($scope.organization.id);
		}
		function loadProjectContacts () {
			return aerosApi.getAllProjectContacts ($scope.organization.id, $scope.projectId);
		}
		var rsp = {};
		function checkReady () {
			if (rsp.org && rsp.proj) {
				$scope.contactsByType = _.groupBy(rsp.org.data.contacts, "contactType");
				$scope.allProjectContacts = _.groupBy(rsp.proj.data.contacts, function (projContact) {
					return projContact.contact.status;
				});
				$scope.projectContacts = _.groupBy($scope.allProjectContacts.Active, "contactType");
				$scope.deletedProjectContacts = _.groupBy($scope.allProjectContacts.Deleted, "contactType");
				_.each(contactTypes, function (type) {
					$scope.contactsByType[type] = $scope.contactsByType[type] || [];
					if ($scope.allow['createContacts']) {
						$scope.contactsByType[type].push({companyName: "Create New " + type});
					}
					if ($scope.projectContacts[type]) {
						$scope.reportContacts[type] = $scope.projectContacts[type][0].contact;
					} else {
						$scope.reportContacts[type] = _.find($scope.contactsByType[type], function(contact) {
							return contact.defaultContact;
						});
					}
				});
			}
		}

		function getProjectContacts() {
			loadProjectContacts().then(function (response) {
				rsp.proj = response;
				checkReady();
			});
		}

		$scope.initContacts = function() {
			loadContacts().then(function (response) {
				rsp.org = response;
				checkReady();
			});
			getProjectContacts();
		};
		$scope.initContacts();

		$scope.changeProjectContact = function (type, contact) {
			if (!$scope.allow['setProjectContacts']) return $scope.reject($scope.projectId);
			if (typeof contact === "string" || (!contact.id && contact.companyName === "Create New " + type) ) {
				if (!$scope.allow['createContacts']) return $scope.reject($scope.projectId);
				// selected "create new contact"
				var obj = {
					contactType: type
				};
				return orgContactModal.open(contactTypes, obj, $scope.allow['setDefaultContacts']).then(function (data) {
					var contact = data.contact;
					aerosApi.createContact($scope.organization.id, contact).then(function (rsp) {
						$scope.changeProjectContact (type, rsp.data.contact);
						if (data.uploadLogo) {
							$scope.uploadLogo(rsp.data.contact);
						}
						$scope.initContacts();
					}, function (rsp) {
						if (rsp.status === 400) {
							Notification.error("Contact " + rsp.data.detail);
						}
					});
				});
			}

			if ($scope.projectContacts[type]) {
				if (contact.id === $scope.reportContacts[type].id) {
					// no change
					return;
				} else {
					// remove old selection
					aerosApi.deleteProjectContact ($scope.organization.id, $scope.projectId, $scope.projectContacts[type][0].id);
				}
			}
			// update UI
			$scope.reportContacts[type] = contact;
			// add selection to DB
			var request = {
				contactType: type,
				contact: {
					id: contact.id || 0 // dummy id for "don't show"
				}
/*
				,overrides: {
					contactName:
					email:
					phone:
				}
 */
			};
			// update DB
			aerosApi.createProjectContact ($scope.organization.id, $scope.projectId, request).then(function (response) {
				getProjectContacts();
				Notification.success("Successfully selected " + contact.companyName + " as " + type + " for the project")
			});
		};

		$scope.uploadLogo = function (contact) {
			uploadLogoModal.open(contact).then(function (file) {
		    	if (file === "delete") {
					aerosApi.deleteContactLogo($scope.organization.id, contact.id).then(function(rtn) {
						Notification.success("Deleted logo for " + contact.companyName);
						$scope.initContacts();
					});
		    	} else {
					aerosApi.setContactLogo($scope.organization.id, contact.id, file).then(function(rtn) {
						Notification.success("Uploaded logo for " + contact.companyName);
						$scope.initContacts();
					});
		    	}
		    });
		};

		$scope.deselectProjects();

	});
}]);
